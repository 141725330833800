import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: 'admin', loadChildren: () => import('./admin/admin-routes') },
  { path: '', loadChildren: () => import('./customer/customer-routes') },
  {
    path: 'pdf-test',
    loadComponent: () => import('./customer/pdf-preview/pdf-preview.component'),
  },
  {
    path: '**',
    loadComponent: () => import('./_shared/components/page-not-found/page-not-found.component'),
  },
];
